export const ruleAddSample = {
  time_from: {
    required: true,
    message: 'Укажите время начала',
    trigger: 'submit',
  },
  time_to: {
    required: true,
    message: 'Укажите время окончания',
    trigger: 'submit',
  },
  count: {
    required: true,
    message: 'Заполните квоту для культуры',
    trigger: 'blur',
  },
  truck_type_codes: {
    type: 'array',
    required: true,
    message: 'Выберите тип автомобилей',
    trigger: 'blur',
  },
  unload_line_id: {
    required: true,
    message: 'Укажите линию',
    trigger: 'blur',
  },
}
